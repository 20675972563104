import * as React from "react";
import { Fragment, useState, useEffect } from "react";
import Layout from "../components/layout";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon, Bars3Icon, EllipsisHorizontalIcon, PlusSmallIcon } from "@heroicons/react/20/solid";
import { BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, ArrowDownTrayIcon } from "@heroicons/react/20/solid";
import windows from "../images/icons8-windows-10-48.png";
import mac from "../images/icons8-mac-50.png";
import store from "../images/Download_on_the_App_Store_Badge_TR_RGB_blk_100217.svg";
import googlePlay from "../images/google-play-badge.png";
import { graphql } from "gatsby";

const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
};
const days = [
  {
    date: "Today",
    dateTime: "2023-03-22",
    transactions: [
      {
        id: 1,
        invoiceNumber: "00012",
        href: "#",
        amount: "$7,600.00 USD",
        tax: "$500.00",
        status: "Paid",
        client: "Reform",
        description: "Website redesign",
        icon: ArrowUpCircleIcon,
      },
      {
        id: 2,
        invoiceNumber: "00011",
        href: "#",
        amount: "$10,000.00 USD",
        status: "Withdraw",
        client: "Tom Cook",
        description: "Salary",
        icon: ArrowDownCircleIcon,
      },
      {
        id: 3,
        invoiceNumber: "00009",
        href: "#",
        amount: "$2,000.00 USD",
        tax: "$130.00",
        status: "Overdue",
        client: "Tuple",
        description: "Logo design",
        icon: ArrowPathIcon,
      },
    ],
  },
  {
    date: "Yesterday",
    dateTime: "2023-03-21",
    transactions: [
      {
        id: 4,
        invoiceNumber: "00010",
        href: "#",
        amount: "$14,000.00 USD",
        tax: "$900.00",
        status: "Paid",
        client: "SavvyCal",
        description: "Website redesign",
        icon: ArrowUpCircleIcon,
      },
    ],
  },
];

// const clients = [
//   {
//     id: 1,
//     name: "Windows",
//     imageUrl: windows,
//     versionNo: "0.0.0.0",
//     url: "",
//   },
//   {
//     id: 2,
//     name: "Mac",
//     imageUrl: mac,
//     versionNo: "2023.09.11.11",
//     url: "",
//   },
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DownloadsPage = () => {
  const [windowsClient, setWindowsClient] = useState({
    id: 1,
    name: "Windows",
    imageUrl: windows,
    versionNo: "0.0.0.0",
    url: "",
  });
  const [macosClient, setMacosClient] = useState({
    id: 2,
    name: "Mac",
    imageUrl: mac,
    versionNo: "0.0.0.0",
    url: "",
  });

  useEffect(() => {
    fetch(`https://api.onaylarim.com/sign/GetSignerAppVersions`)
      .then((response) => response.json())
      .then((resultData) => {
        if (resultData && resultData.result && resultData.result.signerAppWindowsVersion) {
          const signerAppWindowsVersionString = String(resultData.result.signerAppWindowsVersion);
          if (signerAppWindowsVersionString.length === 8) {
            const withDots = signerAppWindowsVersionString.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/g, "$1.$2.$3.$4");
            setWindowsClient({
              id: 1,
              name: "Windows",
              imageUrl: windows,
              versionNo: withDots,
              url: "",
            });
          }
        }
        if (resultData && resultData.result && resultData.result.signerAppMacVersion) {
          const signerAppMacVersionString = String(resultData.result.signerAppMacVersion);
          if (signerAppMacVersionString.length === 8) {
            const withDots = signerAppMacVersionString.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/g, "$1.$2.$3.$4");
            setMacosClient({
              id: 2,
              name: "Mac",
              imageUrl: mac,
              versionNo: withDots,
              url: "",
            });
          }
        }
        if (resultData && resultData.result && resultData.result.signerAppWindowsUrl) {
          const signerAppWindowsUrlString = String(resultData.result.signerAppWindowsUrl);
          const updatedValue = { url: signerAppWindowsUrlString };
          setWindowsClient((windowsClient) => ({
            ...windowsClient,
            ...updatedValue,
          }));
        }
        if (resultData && resultData.result && resultData.result.signerAppMacUrl) {
          const signerAppMacUrlString = String(resultData.result.signerAppMacUrl);
          const updatedValue = { url: signerAppMacUrlString };
          setMacosClient((macosClient) => ({
            ...macosClient,
            ...updatedValue,
          }));
        }
      })
      .catch((e) => {});
  }, []);
  return (
    <Layout pageTitle="İndirmeler">
      <div className="bg-white py-8 sm:py-12 md:my-24">
        {/* <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Uygulamanıza e-imza atma özelliği ekleyin</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">Karmaşık ve kullanımı zor SDK'lar ile uğraşmadan, sadece API kullanarak mevcut uygulamalarınızda yasal geçerli e-imza ve mobil imza atılmasını sağlayabilirsiniz.</p>
          </div>
        </div> */}

        {/* os */}
        <div className="space-y-16 py-16 xl:space-y-32">
          {/* Recent client list*/}
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="flex items-center justify-between">
                <h2 className="text-4xl font-semibold leading-7 text-gray-900">ONAYLARIM e-İmza Aracı</h2>
              </div>
              <div className="mt-2">
                <h2 className="text-sm  leading-7 text-gray-700">Bilgisayarınızda e-imza atmak için işletim sisteminize uygun kurulum dosyasını indirebilirsiniz.</h2>
              </div>
              <ul role="list" className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                <li key={windowsClient.id} className="overflow-hidden rounded-xl border border-gray-200">
                  <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                    <img src={windowsClient.imageUrl} alt={windowsClient.name} className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10" />
                    <div className="text-sm font-medium leading-6 text-gray-900">{windowsClient.name}</div>
                  </div>
                  <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-500">Sürüm numarası</dt>
                      <dd className="text-gray-700">
                        <div className="font-medium text-gray-900">{windowsClient.versionNo}</div>
                      </dd>
                    </div>
                    {/* <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Sürüm tarihi</dt>
                        <dd className="text-gray-700">
                          <time dateTime={client.dateTime}>{client.date}</time>
                        </dd>
                      </div> */}

                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-500"></dt>
                      <dd className="text-gray-700">
                        <a href={windowsClient.url} className="inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600">
                          <ArrowDownTrayIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                          İndir
                        </a>
                      </dd>
                    </div>
                  </dl>
                </li>

                <li key={macosClient.id} className="overflow-hidden rounded-xl border border-gray-200">
                  <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                    <img src={macosClient.imageUrl} alt={macosClient.name} className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10" />
                    <div className="text-sm font-medium leading-6 text-gray-900">{macosClient.name}</div>
                  </div>
                  <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-500">Sürüm numarası</dt>
                      <dd className="text-gray-700">
                        <div className="font-medium text-gray-900">{macosClient.versionNo}</div>
                      </dd>
                    </div>
                    {/* <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Sürüm tarihi</dt>
                        <dd className="text-gray-700">
                          <time dateTime={client.dateTime}>{client.date}</time>
                        </dd>
                      </div> */}

                    <div className="flex justify-between gap-x-4 py-3">
                      <dt className="text-gray-500"></dt>
                      <dd className="text-gray-700">
                        <a href={macosClient.url} className="inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600">
                          <ArrowDownTrayIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                          İndir
                        </a>
                      </dd>
                    </div>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
          {/* Recent client list*/}
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="flex items-center justify-between">
                <h2 className="text-4xl font-semibold leading-7 text-gray-900">ONAYLARIM e-İmza Portali</h2>
              </div>

              <div className="mt-2">
                <h2 className="text-sm  leading-7 text-gray-700">iPhone ve iPadinizden mobil imza atmak için uygulamamızı kullanabilirsiniz.</h2>
              </div>
              <ul role="list" className="mt-6 flex space-x-6 xl:space-x-8">
                <li className="overflow-hidden">
                  <a href="https://apps.apple.com/tr/app/onaylarim-sign/id6456446659?l=tr">
                    <img src={store} alt={windowsClient.name} className="h-12 flex-none rounded-lg bg-white object-cover" />
                  </a>
                </li>
                <li className="overflow-hidden">
                  <img src={googlePlay} alt={windowsClient.name} className="h-12 grayscale brightness-50" />
                  <div className="text-sm mt-2 text-center">Çok yakında!</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <main></main>
    </Layout>
  );
};

export default DownloadsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
